import disposeFarAway from './farAway'
import disposeshopkeeper from './shopkeeper'
import disposeMall from './mall'
import disposeCollocate from './collocate'
import invoice_sdp from './invoice_sdp'
const dispose = {
  path: 'dispose/:merchantId',
  component: resolve => require(['../../../../../pages/admin/merchant/dispose'], resolve),
  redirect: 'dispose/:merchantId/faraway',
  children: [
    disposeFarAway,
    disposeshopkeeper,
    disposeMall,
    disposeCollocate,
	invoice_sdp,
  ],
  meta: {
    requireAuth: true
  }
}

export default dispose