import accessPay from "../accessPay"

import rate from "../rate"

const homeIndex = {
    path: 'home',
    component: resolve => require(['@/pages/admin/merchant/signing/ums/home'], resolve),
    meta: {isFound: true},
    redirect: 'accessPay',
    children:[
        accessPay,
        rate,
    ],
}
export default homeIndex