import aftersalesList from './list'
import aftersalesReplay from './replay'
import aftersalesReplayList from './replay/list'
import wechatlist from './wechatlist'
import alipaylist from './alipaylist'
import alipayreplay from './alipayreplay'
import alipayInfo from './alipayInfo'
import wechatreplay from './wechatreplay'
import wechatInfo from './wechatInfo'
import wechatInfoReplay from './wechatInfoReplay'
import equipmentManagement from './equipmentManagement'
import undividedAccount from './undividedAccount'
import closedownlist from './closedownlist'
import goods from '../platform/goods'
import addclosedownlist from './addclosedownlist'
import merchantRecord from "./merchant/index"
import afterSalesList from "./aftersaleslist/aftersaleslist"
import Invoicetransaction from './Invoicetransaction'
import merchantBlack from "./merchantBlack/index"
import addBlack from "./merchantBlack/addBlack"
import merchantVideo from "./merchantVideo/index"
import notAccountMerchant from './notAccountMerchant'
import merchantAppeals from './merchantAppeals' //申诉列表
import receiverContract from './receiverContract/index' //诸葛分账-合同审核

const aftersales = {
  path: '/aftersales',
  component: resolve => require(['@/pages/admin/aftersales'], resolve),
  redirect: '/aftersales/list',

  children: [
    aftersalesList,
    aftersalesReplay,
    aftersalesReplayList,
    wechatlist,
    alipaylist,
    alipayreplay,
    alipayInfo,
    wechatInfo,
    wechatInfoReplay,
    wechatreplay,
    equipmentManagement,
    undividedAccount,
    closedownlist,
	goods,
    addclosedownlist,
    merchantRecord,
    afterSalesList,
	Invoicetransaction,
    merchantBlack,
    addBlack,
    merchantVideo,
    notAccountMerchant,
	merchantAppeals,
	receiverContract,
  ],
  meta: {
    requireAuth: true
  },
}

export default aftersales
