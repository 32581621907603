const receiverContract = {
    path: 'receiver_contract',
    component: resolve => require(['@/pages/admin/aftersales/receiverContract/index.vue'], resolve),
    meta: {
        requireAuth: true
    },
    children: [

    ]
}

export default receiverContract